import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-scroll'
import { MdNightsStay, MdWbSunny } from "react-icons/md";

const Navbar = ({ darkMode, setDarkMode }) => {
  const [nav, setNav] = useState(false);


  const links = [
    {
      id: 1,
      link: 'home'
    },
    {
      id: 2,
      link: 'skills'
    },
    {
      id: 3,
      link: 'projects'
    },
    {
      id: 4,
      link: 'about'
    },
    {
      id: 5,
      link: 'contact'
    },
  ]

  return (
    <div className="flex justify-between items-center w-full h-20 px-4 dark:text-white text-black bg-white dark:bg-black">
      <div>
        <h1 className="hidden md:block text-5xl font-signature ml-2 py-4">Sophie Jade Wardle</h1>
        <h1 className="md:hidden text-4xl font-signature ml-2 py-4">SJW</h1>
      </div>

      <div onClick={() => setDarkMode(!darkMode)}>
        {
          darkMode ? (
            <MdWbSunny className="text-2xl cursor-pointer" />
          ) : (
            <MdNightsStay className="text-2xl cursor-pointer" />
          )}

      </div>

     {/* Main navigation links */}
     <ul className='hidden md:flex'>
        {links.map(({ id, link }) => (
          <li key={id}
            className='relative px-4 cursor-pointer capitalize font-medium text-gray-500 hover:scale-105 hover:text-white hover:bg-black dark:hover:text-white duration-200'
          >
            <Link to={link} smooth duration={500}>{link}</Link>

          </li>
        ))}
      </ul>

      {/* Mobile navigation menu */}
      <div
        onClick={() => setNav(!nav)}
        className='cursor-pointer pr-4 z-10 text-gray-500 md:hidden'
      >
        {nav ? <FaTimes size={30} /> : <FaBars size={30} />}
      </div>

      {/* Mobile projects dropdown */}
      {nav && (
        <ul className='flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-500 text-gray-500'>
          {links.map(({ id, link }) => (
            <li key={id}
              className='relative px-4 cursor-pointer capitalize py-6 text-4xl'
            >
             <Link 
               onClick={() => setNav(!nav)} 
               to={link} 
               smooth 
               duration={500}
               >
                {link}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default Navbar;