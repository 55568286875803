import React from 'react';
import { Link } from "react-router-dom";
import st from '../assets/smarterthinking.png';
import whatsthat from '../assets/whatsthat.png';
import fdmrest from '../assets/fdmrest.png';
import fdmmvc from '../assets/fdmmvc.png';
import fdmjs from '../assets/fdmjs.png';

const Projects = () => {

  const portfolios = [
    {
      id: 1,
      src: st,
    },
    {
      id: 2,
      src: whatsthat,
      code: 'https://github.com/SophieWardle/WhatsThat_App',
    },
    {
      id: 3,
      src: fdmrest,
      code: 'https://github.com/SophieWardle/Films-REST',
      demo: 'https://youtu.be/p876ZAIqQqc',
    },
    {
      id: 4,
      src: fdmmvc,
      code: 'https://github.com/SophieWardle/Films-MVC',
      demo: 'https://youtu.be/Xj6-7V4trrw',
      
    },
    {
      id: 5,
      src: fdmjs,
      code: 'https://github.com/SophieWardle/Films-Client',
      demo: 'https://youtu.be/juDJgz2mcxU',
    },
  ]
  return (
    <div
      name="projects"
      className='bg-gradient-to-b from-gray-500  to-white dark:from-gray-800 dark:to-black w-full dark:text-white'
    >

      <div className='max-w-screen-large p-4 mx-auto flex flex-col justify-center w-full h-full'>
        <div className='pb-6'>
          <p className='text-4xl font-bold inline border-b-4 border-black dark:border-gray-500'>Projects</p>
          <p className='py-4'>Check out my work</p>
        </div>

        <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 pb-8 sm:px-0 text-center'>
          {portfolios.map(({ id, src, demo, code }) => (
            <div key={id} className='shadow-md dark:shadow-gray-600 shadow-white rounded-lg'>
              <img src={src} alt="" className='rounded-md duration-200 hover:scale-105' />
              <div className='flex items-center justify-center'>
                {demo && (
                  <a href={demo} target="_blank" rel="noopener noreferrer" className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105">
                    Demo
                  </a>
                )}
                {code && (
                  <a href={code} target="_blank" rel="noopener noreferrer" className='w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105'>
                    Code
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;