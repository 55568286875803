import React from 'react'
import bootstrap from '../assets/bootstrap.png';
import css from '../assets/css.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import github from '../assets/github.png';
import react from '../assets/react.png';
import tailwindcss from '../assets/tailwindcss.png'
import java from '../assets/java.png'
import php from '../assets/php.png'
const Skills = () => {
    const tech = [
        {
            id: 1,
            src: html,
            title: 'HTML',
            style: 'shadow-orange-500'
        },
        {
            id: 2,
            src: css,
            title: 'CSS',
            style: 'shadow-blue-500'
        },
        {
            id: 3,
            src: javascript,
            title: 'JavaScript',
            style: 'shadow-yellow-500'
        },
        {
            id: 4,
            src: react,
            title: 'React/RN',
            style: 'shadow-blue-600'
        },
        {
            id: 5,
            src: tailwindcss,
            title: 'Tailwind',
            style: 'shadow-sky-400'
        },
        {
            id: 6,
            src: bootstrap,
            title: 'Bootstrap',
            style: 'shadow-purple-400'
        },
        {
            id: 7,
            src: github,
            title: 'GitHub',
            style: 'shadow-neutral-500'
        },
        {
            id: 8,
            src: java,
            title: 'Java',
            style: 'shadow-rose-500'
        },
        {
            id: 9,
            src: php,
            title: 'PHP',
            style: 'shadow-purple-200'
        },
    ]
    return (
        <div name="skills" className='bg-gradient-to-b from-white to-gray-500 dark:from-black dark:to-gray-800 w-full'>
            <div className='max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full  text-black dark:text-white'>
                <div>
                    <p className='text-4xl font-bold border-b-4 border-black dark:border-gray-500 p-2 inline'>Skills</p>
                    <p className='py-6'>The technologies I've worked with</p>
                </div>

                <div className='w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0'>
                    {
                        tech.map(({ id, src, title, style }) => (
                            <div 
                            key={id}
                            className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}
                            >
                                <img src={src} alt="" className='w-20 mx-auto' />
                                <p className='mt-4'>{title}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Skills
