import React from 'react'
import picture from '../assets/sjw.png'
import { HiArrowNarrowRight } from 'react-icons/hi';
import { Link } from 'react-scroll';
const Home = () => {
    return (
        <div
          name="home"
          className="h-screen w-full bg-gradient-to-b from-white via-white to-gray-500 dark:from-black dark:via-black dark:to-gray-800 sm:mt-18"
        >
            <div
                className='max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row'>
                <div className='flex flex-col justify-center h-full'>
                    <h2 className='text-4xl sm:text-7xl font-bold text-black dark:text-white'>
                        I'm a Full Stack Developer
                    </h2>
                    <p className='text-gray-500 py-4 max-w-md'>
                    As a first-class graduate in software engineering, I am passionate about creating innovative and seamless digital experiences. With a strong foundation in both front-end and back-end development, I possess the versatility to shape ideas into functional and visually captivating solutions.
                    </p>

                    <div className='flex flex-col items-center sm:flex-row'>
                        <Link 
                        to="projects"
                        smooth
                        duration={500}
                        className='group text-white w-fit px-6 py-3 my-2 mr-4 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer'
                        >
                            Portfolio
                            <span className='group-hover:rotate-90 duration-300'>
                                <HiArrowNarrowRight size={20}
                                className='ml-1'></HiArrowNarrowRight>
                            </span>
                        </Link>
                        <a
              href='/CV.docx'  // Replace with the correct path to your CV PDF
              className='group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer'
              download
            >
              Download My CV
              <span className='group-hover:rotate-90 duration-300'>
                <HiArrowNarrowRight size={20} className='ml-1'></HiArrowNarrowRight>
              </span>
            </a>
                    </div>
                </div>
                <div>
                    <img 
                    src={picture} 
                    alt='A digitial art of me'
                    className='rounded-2xl mx-auto w-2/3 md:w-half border border-black' />
                </div>
            </div>
        </div>
    )
}

export default Home