import { useState } from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import SocialLinks from "./components/SocialLinks";
import About from "./components/About";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Contact from "./components/Contact";

function App() {
  const [darkMode, setDarkMode] = useState(true);
  return (
   <div className={darkMode && "dark"}>
    <Navbar darkMode={darkMode} setDarkMode={setDarkMode}></Navbar>
    <Home></Home>
    <About></About>
    <Skills></Skills>
    <Projects></Projects>  
    <Contact></Contact>
    <SocialLinks></SocialLinks>
   </div>
  );
}

export default App;
