import React from 'react'

const About = () => {
    return (
        <div
            name="about"
            className='w-full h-screen bg-gradient-to-b from-gray-500 to-white dark:from-gray-800 dark:to-black dark:text-white'
        >
            <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full'>
                <div className='pb-4'>
                    <p className='text-4xl font-bold incline border-b-4 border-black dark:border-gray-500'>
                        About Me
                    </p>
                </div>
                <p className='md:text-xl lg:text-xl sm:text-sm pt-10'>
                From my earliest interactions with computers, I've been captivated by their potential to shape our world, leading me to pursue computer science in college. This journey drove me to further my education in software engineering, learning from professionals who shaped my growth.
                </p>
                <br />
                <p className='md:text-xl lg:text-xl sm:text-sm'>
                As a woman in STEM, I value diversity in technology. I embrace the challenges of being a trailblazer and my unique outlook highlights the significance of collaboration, empathy, and a growth mindset in addressing challenges.
                </p>
                <br />
                <p className='md:text-xl lg:text-xl sm:text-sm'>
                With experience in both front-end and back-end development, I bring holistic solutions through continuous learning, excited by the evolving landscape of tech. Creating impactful applications and tackling challenges drives me, and I'm eager to contribute to innovative projects.                </p>
            </div>
        </div>
    )
}

export default About